import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_sorokins_in_national_team_debut" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Владиславс Сорокинс (23 года) и Алексейс Савельевс (21 год) одновременно дебютировали за Национальную сборную Латвии. В товарищеском матче против сборной Сан-Марино оба футболиста провели на поле 90 минут. Матч закончился со счётом 0:3 в пользу сборной Латвии.</p>
          <p>В следующем официальном матче против команды Фарерских островов Алексейс Савельевс вышел не замену на 60 минуте и, таким образом, оформил дебют за сборную Латвии в официальных матчах. Матч закончился со счётом 1:1.</p>
          <p>В заключительном матче сборная Латвии в рамках Лиги Наций встречалась со сборной Андорры. Матч закончился со счётом 0:5 в пользу сборной Латвии. Алексейс Савельевс вышел на замену во втором тайме и провёл на поле 35 минут. Таким образом Алексейс принял участие во всех трёх запланированных матчах сборной Латвии в это международное окно.</p>
        </div>
      </div>
    )
  }
}